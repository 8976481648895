import React from "react";
import Layout from "../../other/layout";
import ManufacturingDistributionLocations from "../../components/AC-StaticPages/why-choose-dfs/manufacturing-distribution-locations/ManufacturingDistributionLocations";
import SEO from "../../other/seo";

export default function manufacturingDistributionLocations() {
  return (
    <Layout>
      <SEO title="Manufacturing Distribution Locations" />

      <ManufacturingDistributionLocations />
    </Layout>
  );
}
