import React from "react";
import dfs_map from "../../../../assets/img/dfs-us-map.jpg";
import locations from "../../../../assets/img/NationwideLocations_Hero.jpg";

import styles from "../../style/styles.module.css";

export default function ManufacturingDistributionLocations() {
  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.contentManu}>
          <h1 className={styles.title}>
            Manufacturing & Distribution Locations
          </h1>
          <div>
            <div className="col-md-3">
              <h3 className="locationLinksTitle">By Location:</h3>
              <ul className="left-product-nav">
                <li>
                  <a
                    href=" https://www.dfsonline.com/webasset/b2b/maps/PT583203E_Chicago.pdf"
                    target="_blank"
                  >
                    Chicago, IL (opens in new window)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.dfsonline.com/webasset/b2b/maps/PT583203E_KansasCity.pdf"
                    target="_blank"
                  >
                    Kansas City, KS (opens in new window)
                  </a>
                </li>
                <li>
                  <a
                    href=" https://www.dfsonline.com/webasset/b2b/maps/PT583203E_Midland.pdf"
                    target="_blank"
                  >
                    Midland, Ontario (opens in new window)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.dfsonline.com/webasset/b2b/maps/PT583203E_MountainLakes.pdf"
                    target="_blank"
                  >
                    Mountain Lakes, NJ (opens in new window)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.dfsonline.com/webasset/b2b/maps/PT583203E_SaltLakeCity.pdf"
                    target="_blank"
                  >
                    Salt Lake City, UT (opens in new window)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.dfsonline.com/webasset/b2b/maps/PT583203E_Streetsboro.pdf"
                    target="_blank"
                  >
                    Streetsboro, OH (opens in new window)
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.dfsonline.com/webasset/b2b/maps/PT583203E_Townsend.pdf"
                    target="_blank"
                  >
                    Townsend, MA (opens in new window)
                  </a>
                </li>

                <hr />

                <li>
                  <a
                    href="https://www.dfsonline.com/webasset/b2b/maps/PT583203E_CreatingImpressions__Canada.pdf"
                    target="_blank"
                  >
                    Product Overview (opens in new window)
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-md-9">
              <h2>USA and Canada Production Facilities</h2>
              <img
                className={styles.mapImg}
                src={dfs_map}
                alt="Map of DFS facilities in North America. From left to right: Oakland, CA, Salt Lake City, UT, Kansas City, KS, Arden Hills, MN, Maryville, MO, Chicago, IL, Lithia Springs, GA, Streetsboro, OH, Midland, ON, Mountain Lakes, NJ, Townsend, MA."
              />
            </div>
            <div class="clearfix"></div>
          </div>

          <hr className={styles.productHr} />

          <h2>8 production facilities nationwide</h2>
          <img src={locations} alt="Forklift worker in warehouse"></img>
          <ul>
            <li>Fast order fulfillment</li>
            <li>Low shipping costs</li>
            <li>More happy customers and repeat orders!</li>
          </ul>
        </div>
      </div>
    </div>
  );
}
